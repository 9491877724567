import { ArrowForwardIos } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useMediaQuery } from "@mui/material";
import { accordionProps, borderBottomProps, flexRowReverseProps, squareNavyButtonInSmallScreen, tabHeaderProps } from "@styles/global-css-class";
import { SlateButton } from "./Common";
export const AccordionTab = ({
  open,
  index,
  label,
  handleOpenTab,
  handleOpenNextTab,
  nextTabLabel,
  keepOriginalNextTabLabel,
  children
}: {
  open: boolean;
  index: number;
  label: string;
  handleOpenTab: Function;
  handleOpenNextTab?: Function;
  nextTabLabel?: string;
  keepOriginalNextTabLabel?: boolean;
  children?: React.ReactNode;
}) => {
  const size = useMediaQuery("(max-width:1366px) and (max-height:768px)") ? "small" : "large";
  return <Accordion sx={accordionProps} expanded={open} data-sentry-element="Accordion" data-sentry-component="AccordionTab" data-sentry-source-file="AccordionTab.tsx">
            <AccordionSummary expandIcon={<ArrowForwardIos />} aria-controls={`${label}-content`} id={`${label}-header`} sx={tabHeaderProps} onClick={() => handleOpenTab()} data-sentry-element="AccordionSummary" data-sentry-source-file="AccordionTab.tsx">
                0{index} {label}
            </AccordionSummary>
            <AccordionDetails sx={{
      px: 0,
      py: 3
    }} data-sentry-element="AccordionDetails" data-sentry-source-file="AccordionTab.tsx">
                {children}
                {handleOpenNextTab && <Grid item sx={{
        ...flexRowReverseProps,
        ...borderBottomProps,
        py: 2
      }}>
                    <SlateButton variant='contained' size={size} onClick={() => handleOpenNextTab()} sx={squareNavyButtonInSmallScreen}>
                        {keepOriginalNextTabLabel ? <Typography>{nextTabLabel}</Typography> : <Typography>{`Next: 0${index + 1} ${nextTabLabel}`}</Typography>}
                    </SlateButton>
                </Grid>}
            </AccordionDetails>
        </Accordion>;
};