import { SxProps, Theme, styled } from "@mui/material/styles";
import { Badge, BadgeProps, Button, FormLabel, Paper, Switch, SwitchProps, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { colors } from "./ThemeRegistry/theme";
import { IDateFilterParams, IGroupCellRendererParams, IRowNode, ISetFilterParams } from "ag-grid-community";
import { AccordionTab } from "./AccordionTab";
import { flexCenterProps } from "@styles/global-css-class";
const NavyButton = styled(Button)(() => ({
  backgroundColor: colors.navy,
  borderRadius: 20,
  textTransform: "none",
  borderColor: colors.navy
}));
const OutlinedButton = styled(Button)(() => ({
  borderRadius: 20,
  textTransform: "none",
  color: colors.navy,
  borderColor: colors.navy
}));
const GreenButton = styled(Button)(() => ({
  backgroundColor: colors.green,
  borderRadius: 20,
  textTransform: "none",
  borderColor: colors.green,
  color: colors.white
}));
const OrangeButton = styled(Button)(() => ({
  backgroundColor: colors.orange,
  borderRadius: 20,
  textTransform: "none",
  borderColor: colors.orange,
  color: colors.white
}));
const RedButton = styled(Button)(() => ({
  backgroundColor: colors.red,
  borderRadius: 20,
  textTransform: "none",
  borderColor: colors.red,
  color: colors.white
}));
const SlateButton = styled(Button)(() => ({
  backgroundColor: colors.slate,
  borderRadius: 20,
  textTransform: "none",
  borderColor: colors.slate
}));
const SquareNavyButton = styled(Button)(() => ({
  backgroundColor: colors.navy,
  borderRadius: 5,
  textTransform: "none",
  borderColor: colors.navy,
  color: colors.white
}));
const SquareRedButton = styled(Button)(() => ({
  backgroundColor: colors.red,
  borderRadius: 5,
  textTransform: "none",
  borderColor: colors.navy,
  color: "white",
  '&.Mui-disabled': {
    backgroundColor: colors.white,
    border: "1px solid"
  }
}));
const SquareSlateButton = styled(Button)(() => ({
  backgroundColor: colors.slate,
  borderRadius: 5,
  textTransform: "none",
  borderColor: colors.slate,
  color: "white",
  border: `1px solid ${colors.slate}`,
  "&:hover": {
    backgroundColor: colors.white,
    color: colors.slate
  }
}));
const SquareOutlineButton = styled(Button)(() => ({
  borderRadius: 5,
  textTransform: "none",
  borderColor: colors.navy,
  color: colors.navy
}));
const SquareOutlineSlateButton = styled(Button)(() => ({
  borderRadius: 5,
  textTransform: "none",
  border: "1px solid",
  borderColor: colors.slate,
  backgroundColor: colors.white,
  color: colors.slate,
  "&:hover": {
    backgroundColor: colors.slate,
    color: colors.white
  },
  "&:disabled": {
    backgroundColor: colors.white,
    color: colors.gray,
    borderColor: colors.gray
  },
  "&.active": {
    backgroundColor: colors.slate,
    color: colors.white
  }
}));
export const disabledButtonStyles = {
  color: colors.gray,
  backgroundColor: "#e3e3e4"
};
const Title = styled(Typography)(() => ({
  textTransform: "none",
  color: colors.navy,
  fontSize: "22pt",
  padding: "40px 20px"
}));
const TitleSection = styled(Typography)(() => ({
  textTransform: "none",
  color: colors.navy,
  fontSize: "22pt"
}));
const SmallTitleSection = styled(Typography)(() => ({
  fontSize: "26px",
  fontWeight: 400,
  fontFamily: "Franklin Gothic Medium",
  lineHeight: "25px",
  overflowWrap: "break-word",
  textTransform: "none",
  color: colors.navy
}));
const Item = styled(Paper)(({
  theme
}) => ({
  backgroundColor: "background.paper",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: "none",
  fontSize: 15
}));
const OutlinedRadius5Button = styled(Button)(() => ({
  borderRadius: 5,
  textTransform: "none",
  color: colors.navy,
  borderColor: colors.navy
}));
const SuccessButton = styled(Button)(() => ({
  padding: "10px, 24px, 10px, 24px",
  minWidth: 150,
  height: 40,
  borderRadius: 100,
  textTransform: "none",
  color: colors.white,
  backgroundColor: colors.success,
  ":disabled": disabledButtonStyles,
  ":hover": {
    backgroundColor: colors.successHover
  }
}));
const WarningButton = styled(Button)(() => ({
  padding: "10px, 24px, 10px, 24px",
  minWidth: 150,
  height: 40,
  borderRadius: 100,
  textTransform: "none",
  color: colors.white,
  backgroundColor: colors.warning,
  ":disabled": disabledButtonStyles,
  ":hover": {
    backgroundColor: colors.warningHover,
    color: colors.textColorHover
  }
}));
const WarningButtonOrange = styled(Button)(() => ({
  padding: "10px, 24px, 10px, 24px",
  minWidth: 150,
  height: 40,
  borderRadius: 100,
  textTransform: "none",
  color: colors.white,
  backgroundColor: colors.warningOrange,
  ":disabled": disabledButtonStyles,
  ":hover": {
    backgroundColor: colors.warningOrangeHover
  }
}));
const ErrorButton = styled(Button)(() => ({
  padding: "10px, 24px, 10px, 24px",
  minWidth: 150,
  height: 40,
  borderRadius: 100,
  textTransform: "none",
  color: colors.white,
  backgroundColor: colors.pink,
  ":disabled": disabledButtonStyles,
  ":hover": {
    backgroundColor: colors.pink
  }
}));
const DefaultButton = styled(Button)(() => ({
  padding: "10px, 24px, 10px, 24px",
  minWidth: 150,
  height: 40,
  borderRadius: 100,
  textTransform: "none",
  color: colors.white,
  backgroundColor: colors.default,
  ":hover": {
    backgroundColor: colors.default,
    filter: "brightness(80%)"
  },
  ":disabled": disabledButtonStyles
}));
const DefaultOutlineButton = styled(Button)(() => ({
  padding: "10px, 24px, 10px, 24px",
  width: 150,
  height: 40,
  borderRadius: 100,
  textTransform: "none",
  color: colors.default,
  backgroundColor: colors.white,
  ":hover": {
    backgroundColor: colors.white,
    filter: "brightness(80%)"
  },
  border: "1px solid",
  borderColor: colors.default
}));
const IconButton = styled(Button)(() => ({
  textTransform: "none",
  color: colors.navy,
  display: "inline-block"
}));
const StyledSpan = styled("span")(() => ({
  textTransform: "none",
  color: colors.navy,
  display: "inline-block"
}));
const StyledFormLabel = styled(FormLabel)(() => ({
  color: colors.gray,
  "@media screen and (max-width: 1366px) and (max-height: 768px)": {
    fontSize: "14px"
  }
}));
export const StyledBadge = styled(Badge)<BadgeProps>(({
  theme
}) => ({
  '& .MuiBadge-badge': {
    right: "20%",
    top: "20%"
  }
}));
export const HtmlTooltip = styled(({
  className,
  ...props
}: TooltipProps) => <Tooltip {...props} classes={{
  popper: className
}} />)(({
  theme
}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(15),
    border: '1px solid #dadde9',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'
  }
}));
export const defaultSelectStyle = {
  p: "2px 3px 0px 3px",
  "@media screen and (max-width: 1366px)": {
    fontSize: "14px"
  },
  "& .MuiSelect-select": {
    p: "7px 6px 7px 6px"
  },
  "& .MuiSelect-select .notranslate": {
    ...flexCenterProps
  }
};
const filterDateParams: IDateFilterParams = {
  comparator: (filterLocalDate: Date, cellValue: string) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
    if (filterLocalDate.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDate) {
      return -1;
    }
    if (cellDate > filterLocalDate) {
      return 1;
    }
    return 0;
  },
  includeBlanksInRange: false
};
const filterNumberParams: ISetFilterParams = {
  comparator: (a: string | null, b: string | null) => {
    const valA = a == null ? 0 : parseInt(a);
    const valB = b == null ? 0 : parseInt(b);
    if (valA === valB) return 0;
    return valA > valB ? 1 : -1;
  }
};
const stringComparator = {
  comparator: (valueA: string, valueB: string, nodeA: IRowNode<any>, nodeB: IRowNode<any>, isDescending: boolean) => {
    if (valueA === null && valueB === null || valueA === undefined && valueB === undefined) {
      return 0;
    }
    if (valueA === null || valueA === undefined) {
      return -1;
    }
    if (valueB === null || valueB === undefined) {
      return 1;
    }
    const upperValueA = valueA.toUpperCase();
    const upperValueB = valueB.toUpperCase();
    if (upperValueA === upperValueB) return 0;
    return upperValueA > upperValueB ? 1 : -1;
  }
};
const filterParams = {
  maxNumConditions: 1,
  buttons: ["apply", "reset"]
};
const filterResetParams = {
  maxNumConditions: 1,
  buttons: ["reset"]
};
const checkSvgIcon = `url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><rect width="24" height="24" fill="white"/><path fill="%234298b5" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`;
const disabledCheckSvgIcon = `url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><rect width="24" height="24" fill="white"/><path fill="%231D1B20" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`;
const ToggleSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({
  theme
}) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "transparent",
      backgroundSize: "contain",
      backgroundImage: checkSvgIcon,
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
        backgroundColor: "#4298b5"
      },
      "&.Mui-disabled": {
        opacity: 0.5
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        backgroundSize: "contain",
        backgroundImage: disabledCheckSvgIcon
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#4298b5",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      backgroundColor: "#1D1B201F"
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundSize: "contain"
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));
const genericNavMenuIconSx: SxProps<Theme> = {
  width: 20,
  minWidth: 20,
  mt: 0.75,
  mb: 0.75,
  color: "inherit"
};
const genericMenuIconSx: SxProps<Theme> = {
  width: 20,
  minWidth: 20,
  color: "inherit"
};
const cellRendererParams: IGroupCellRendererParams = {
  checkbox: true
};
const responsiveGrids = {
  mdFormControl: 6,
  xsFormControl: 12
};
export { NavyButton, OutlinedButton, GreenButton, OrangeButton, RedButton, SlateButton, SquareNavyButton, SquareSlateButton, SquareOutlineButton, DefaultButton, SuccessButton, WarningButton, WarningButtonOrange, DefaultOutlineButton, SquareOutlineSlateButton, ErrorButton, Title, TitleSection, Item, OutlinedRadius5Button, IconButton, filterDateParams, filterParams, filterNumberParams, ToggleSwitch, filterResetParams, StyledSpan, SmallTitleSection, genericNavMenuIconSx, genericMenuIconSx, cellRendererParams, StyledFormLabel, SquareRedButton, stringComparator, AccordionTab, responsiveGrids };