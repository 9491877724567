'use client';
import { createTheme } from "@mui/material/styles";
import { Libre_Franklin } from "next/font/google";

const libreFranklin = Libre_Franklin({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

export const colors = {
  green: "#00B140",
  navy: "#0C2340",
  gray: "#97999B",
  orange: "#DB6B30",
  slate: "#4298B5",
  yellow: "#FFB600",
  pink: "#DF4661",
  paper: "#454545",
  white: "#FFFFFF",
  red: "#DF4661",
  blue: "#3a95de",
  black: "#000000",

  success: "#00AE42",
  successHover: "#007D30",
  successDisabled: "#1d8947",
  error: "#DF4661",
  errorHover: "#DF4661",
  warningOrange: "#F06B25",
  warningOrangeHover: "#E64F00",
  warning: "#FFC942",
  warningHover: "#FFBF1F",
  textColorHover: "#7B5800",
  default: "#002340",
  defaultHover: "#002340",

  divider: "#FFFFFF26",
  slate10Per: "#4298B51A",
  black15Per: "#00000026",
  black60Per: "#00000099",
  defaultBorderColor: "#00000026"
};

export const defaultStyles = {
  appPadding: "50px",
  appPaddingRight: "50px",
  appPaddingLeft: "50px",
  appPaddingTop: "30px",
  appPaddingBottom: "30px",
  pagePadding: "0px 50px 20px 50px",
  agGridHeight: "calc(100vh - 200px)",
  footerHeight: "200px",
  modalBorderRadius: "20px",
  modalWidth: "720px",
  tooltipBorderRadius: "5px",
  tooltipMaxWidth: "400px",
  defaultAGGridClassName: "ag-theme-quartz",
  minWidth: "1024px",
};

export const borderBottomStyles = {
  borderBottomColor: colors.defaultBorderColor,
  borderBottomStyle: "solid",
  borderBottomWidth: "1px"
};

export const sideNaveStyles = {
  width: 275,
  smallWidth: 40,
  paddingRight: "30px",
  paddingLeft: "30px",
  headerMinHeight: "130px"
};

const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    background: {
      paper: colors.white,
    },
  },
  typography: {
    fontFamily: libreFranklin.style.fontFamily,
  },
  zIndex: {
    appBar: 1201,
  },
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
});

const theme = createTheme(appTheme, {
  palette: {
    primaryGreen: appTheme.palette.augmentColor({
      color: {
        main: colors.green,
      },
    }),
    primaryNavy: appTheme.palette.augmentColor({
      color: {
        main: colors.navy,
      },
    }),
    primaryGray: appTheme.palette.augmentColor({
      color: {
        main: colors.gray,
      },
    }),
    primaryPaper: appTheme.palette.augmentColor({
      color: {
        main: colors.paper,
      },
    }),
    secondaryOrange: appTheme.palette.augmentColor({
      color: {
        main: colors.orange,
      },
    }),
    secondarySlate: appTheme.palette.augmentColor({
      color: {
        main: colors.slate,
      },
    }),
    secondaryYellow: appTheme.palette.augmentColor({
      color: {
        main: colors.yellow,
      },
    }),
    secondaryPink: appTheme.palette.augmentColor({
      color: {
        main: colors.pink,
      },
    }),
  },
});

export default theme;
